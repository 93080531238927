import React, { Component, useState, useEffect, useRef } from "react";
import SplitType from "split-type";
import gsap from "gsap";
// get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";
import './assets/App.scss';
function App() {
    const trainSiriRef = useRef(null);

  useEffect(() => {
    const mainHeading = new SplitType("h1.word", { types: "words" });
    const words = mainHeading.words;

    gsap.fromTo(
      words,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 2,
        ease: "power4.out",
      }
    );
    const mainPara = new SplitType("p.word", { types: "lines" });
    const words2 = mainPara.lines;

    gsap.fromTo(
      words2,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 2,
        ease: "power4.out",
      }
    );
    
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".banner-img", {
      y: -50,
      opacity: 1,
      duration: 1,
      delay: 1.5,
      ScrollTrigger: {
        trigger: ".banner-img",
        toggleActions: "play none none reset",
        // scrub: 1, // Set scrub to 1 to enable smooth scrolling animation
      },
    });

    gsap.to(".siri-logo", {
      y: -50,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: ".siri-logo",
        toggleActions: "play none none reset",
        // scrub: 1, // Set scrub to 1 to enable smooth scrolling animation
      }
    });
    gsap.to(".user-text", {
      y: -10,
      opacity: 1,
      duration: 1,
      delay: 1.5,
      scrollTrigger: {
        trigger: ".user-text",
        toggleActions: "play none none reset"
      }
    });
    gsap.to(".siri-text", {
      y: -10,
      opacity: 1,
      duration: 1,
      delay: 2,
      scrollTrigger: {
        trigger: ".siri-text",
        toggleActions: "play none none reset"
      }
    });
    gsap.to(".row-1", {
      y: -80,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: ".row-1",
        toggleActions: "play none none reset"
      }
    });
    gsap.to(".row-2", {
      y: -80,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: ".row-2",
        toggleActions: "play none none reset"
      }
    });
    gsap.to(".row-3", {
      y: -80,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: ".row-3",
        toggleActions: "play none none reset"
      }
    });
    gsap.to(".row-4", {
      y: -80,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: ".row-4",
        toggleActions: "play none none reset"
      }
    });
    gsap.to(".row-5", {
      y: -80,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: ".row-5",
        toggleActions: "play none none reset"
      }
    });
  }, []);

  return (
    <div>
      <canvas className="orb-canvas"></canvas>

      <header>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src="/images/logo-white.png" alt="" className="brand"></img>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Learn
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Contact Us
                  </a>
                </li>
              </ul>
              <div className="ms-2 position-relative">
                <a href="https://apps.apple.com/us/app/cardup/id1592263043" target="_blank" className="home-btn-btn">
                  Get Started
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.37"
                      height="9.178"
                      viewBox="0 0 16.37 9.178"
                    >
                      <g
                        id="Group_69"
                        data-name="Group 69"
                        transform="translate(0.75 1.061)"
                      >
                        <path
                          id="Path_1"
                          data-name="Path 1"
                          d="M475.781,1007.832h14.87"
                          transform="translate(-475.781 -1004.304)"
                          fill="none"
                          stroke="#0b0518"
                          strokeLinecap="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_2"
                          data-name="Path 2"
                          d="M511.62,997.394l3.528,3.528-3.528,3.528"
                          transform="translate(-500.278 -997.394)"
                          fill="none"
                          stroke="#0b0518"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <section className="banner-wrapper">
        <div className="container position-relative z-1">
          <div className="row justify-content-center">
            <div
              className="row justify-content-center align-items-center"
              animate
            >
              <div className="col-md-7">
                <h1 className="word">First impressions matter!</h1>
                <p className="word">
                  Elevate your brand with our captivating blend of print and
                  digital introductions. Experience the perfect synergy of
                  elegance and technology
                </p>
                <div></div>
                <a href="https://apps.apple.com/us/app/cardup/id1592263043" target="_blank" className="btn">
                  <span className="btn__circle"></span>
                  <span className="btn__white-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="icon-arrow-right"
                      viewBox="0 0 21 12"
                    >
                      <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                    </svg>
                  </span>
                  <span className="btn__text">Discover the project</span>
                </a>
              </div>
              <div className="col-md-5 text-center position-relative">
                <img
                  src="./images/Mockup.png"
                  alt=""
                  className="img-fluid banner-img"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-img-1"></div>
        <div className="banner-img-2"></div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center row-1">
            <div className="col-md-6 order-2 order-md-1">
              <h2 className="fw-bold">INNOVATIVE DIGITAL PRINTS FOR YOUR BUSINESS CARD</h2>
              <p>
                Always Accessible: Your Permanent Presence, Any Phone, Any Place, Anytime Absolutely FREE!
              </p>
              <a href="https://apps.apple.com/us/app/cardup/id1592263043" target="_blank" className="btn">
                  <span className="btn__circle"></span>
                  <span className="btn__white-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="icon-arrow-right"
                      viewBox="0 0 21 12"
                    >
                      <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                    </svg>
                  </span>
                  <span className="btn__text">Get Started Now</span>
                </a>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <img src="./images/card.png" className="img-fluid" alt="" />
            </div>
          </div>
          <div className="row align-items-center row-2">
            <div className="col-md-6">
              <img src="./images/mark-mockup.png" className="img-fluid" alt="" />
            </div>
            <div className="col-md-6">
              <h2 className="fw-bold">Peace of mind when you are out of paper cards</h2>
              <p>
                Cardbook: Sending Your Card Anywhere, From Face-to-Face to Six Thousand Miles Away, Hassle-Free!
              </p>
              <a href="https://apps.apple.com/us/app/cardup/id1592263043" target="_blank" className="btn">
                  <span className="btn__circle"></span>
                  <span className="btn__white-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="icon-arrow-right"
                      viewBox="0 0 21 12"
                    >
                      <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                    </svg>
                  </span>
                  <span className="btn__text">Get Started Now</span>
                </a>
            </div>
          </div>
          <div className="row align-items-center row-3">

            <div className="col-md-6 order-2 order-md-1">
              <h2 className="fw-bold">Transfers you to their phone in a tap</h2>
              <p>
                Effortless Call Transfer: Tap for Instant Connection, So They Recognize You When You Call!
              </p>
              <a href="https://apps.apple.com/us/app/cardup/id1592263043" target="_blank" className="btn">
                  <span className="btn__circle"></span>
                  <span className="btn__white-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="icon-arrow-right"
                      viewBox="0 0 21 12"
                    >
                      <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                    </svg>
                  </span>
                  <span className="btn__text">Get Started Now</span>
                </a>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <img src="./images/phonestap.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-wrapper">
        <div className="container">
          <div className="row justify-content-center row-4">
            <div className="col-lg-6 text-center">
              <h2 className="text-uppercase fw-bold">Trains Siri to find you</h2>
              <p>
                Cardbook gets you noticed cause it's built for you. Introducing
                our all-new device optimization engine with advanced
                meta-tagging.
              </p>
              <div className="train-siri">
                <img src="/images/e74938ad572b46715461319e30e3d174.png" alt="" className="iphone"></img>
                <img src="images/user-text.png" className="user-text" alt="" />
                <img src="images/siri-text.png" className="siri-text" alt="" />
                <img src="images/Siri_new_logo.png" className="siri-logo" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="team-wrapper">
        <div className="container">
          <div className="row justify-content-center row-5">
            <div className="col-lg-6 text-center">
              <h2 className="fw-bold">Team blue</h2>
              <p>
                With every card you share, you're not just making connections -
                you're supporting Team Blue. Join us in our mission to empower
                and inspire, one card at a time. Together, we're making a
                difference.
              </p>
              <div className="pt-2 team-top-div text-center">
                <img
                  src="/images/blue-team.png"
                  alt=""
                  className="img-fluid team-img"
                ></img>
                <img
                  src="/images/Realtor-logo.png"
                  alt=""
                  className="img-fluid realtor-logo"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center">
              <h2 className="fw-bold">Ready to order or still have questions?</h2>
              <ul className="faq-list list-unstyled mt-3">
                <li>
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    data-bs-target="#faq1"
                  >
                    <div className="d-flex">
                      <h6 className="px-2 mt-1 ">How does this works?</h6>
                      <i className="fas fa-chevron-down icon-show text-white"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="2em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></i>
                      <i className="fas fa-chevron-up icon-close"><svg xmlns="http://www.w3.org/2000/svg" height="2em" fill='currentColor' viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg></i>
                    </div>
                  </a>
                  <div
                    id="faq1"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Feugiat pretium nibh ipsum consequat. Tempus iaculis urna
                      id volutpat lacus laoreet non curabitur gravida.
                    </p>
                  </div>
                </li>
                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2"
                    className="collapsed"
                  >
                    <div className="d-flex">
                      <h6 className="px-2 mt-1 ">
                        What is team blue and how do i join it?
                      </h6>
                      <i className="fas fa-chevron-down icon-show text-white"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="2em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></i>
                      <i className="fas fa-chevron-up icon-close"><svg xmlns="http://www.w3.org/2000/svg" height="2em" fill='currentColor' viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg></i>
                    </div>
                  </a>
                  <div
                    id="faq2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Dolor sit amet consectetur adipiscing elit pellentesque
                      habitant morbi. Id interdum velit laoreet id donec
                      ultrices.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq3"
                    className="collapsed"
                  >
                    <div className="d-flex">
                      <h6 className="px-2 mt-1 ">
                        Do i have to pay for anything?
                      </h6>
                      <i className="fas fa-chevron-down icon-show text-white"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="2em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></i>
                      <i className="fas fa-chevron-up icon-close"><svg xmlns="http://www.w3.org/2000/svg" height="2em" fill='currentColor' viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg></i>
                    </div>
                  </a>
                  <div
                    id="faq3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Eleifend mi in nulla posuere sollicitudin aliquam ultrices
                      sagittis orci. Faucibus pulvinar elementum integer enim.
                    </p>
                  </div>
                </li>

                <li>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq4"
                    className="collapsed"
                  >
                    <div className="d-flex">
                      <h6 className="px-2 mt-1 ">How do i order my cards?</h6>
                      <i className="fas fa-chevron-down icon-show text-white"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="2em" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></i>
                      <i className="fas fa-chevron-up icon-close"><svg xmlns="http://www.w3.org/2000/svg" height="2em" fill='currentColor' viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg></i>
                    </div>
                  </a>
                  <div
                    id="faq4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Dolor sit amet consectetur adipiscing elit pellentesque
                      habitant morbi. Id interdum velit laoreet id donec
                      ultrices.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        className="footer-wrapper"
        style={{
          background: `url(${"images/footer.png"}) no-repeat center`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-7 text-center">
              <h2 className="fw-bold">Embrace the Experience!</h2>
              <p>
                Ready to dive in? Get started by ordering now or download for
                free on Google Play Store or Apple Store.
              </p>
            </div>
            <div className="d-flex justify-content-start justify-content-md-center">
              <a href="https://apps.apple.com/us/app/cardup/id1592263043" target="_blank" className="btn">
                  <span className="btn__circle"></span>
                  <span className="btn__white-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="icon-arrow-right"
                      viewBox="0 0 21 12"
                    >
                      <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z"></path>
                    </svg>
                  </span>
                  <span className="btn__text">Get on AppStore Now</span>
                </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
